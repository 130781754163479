<template>
    <section class="navbar-header-section">
        <app-nav></app-nav>
    </section>
    <main>
        <div class="container">
            <router-view></router-view>
        </div>
    </main>
    <footer class="blog-footer">
        <app-footer></app-footer>
    </footer>
</template>

<script>
import Nav from './components/Nav'
import Footer from './components/Footer'

export default {
  data () {
    return {}
  },
  components: {
    appNav: Nav,
    appFooter: Footer
  }
}
</script>

<style lang="scss">
    @import 'assets/scss/variables';
    @import '~bootstrap/scss/bootstrap.scss';
    @import '~@fortawesome/fontawesome-free/css/all.css';
</style>
