<template>
    <div class="container">
        <small class="copyright">Hox Developers &copy; <a href="https://hoxdev.com/" target="_blank">Hoxdev</a></small>
        <br>
        <small>Symfony <i class="far fa-heart"></i></small>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
