<template>
    <section class="header-blog-section mt-2">
        <div class="p-4 p-md-5 rounded">
            <div class="col-md-6 px-0">
                <h1>Добро пожаловать на мой блог!</h1>
                <p class="lead my-3">Кратко обо мне:</p>
                <p class="lead my-3">Мое имя Роман, я являюсь специалистом в сфере информационных технологий,
                    магистр технических наук. В основном занимаюсь backend разработкой.</p>

                <ul class="social-list list-inline mx-auto pt-3">
                    <li class="list-inline-item" data-toggle="tooltip" data-placement="top"
                        title="Резюме на HeadHunter"><a
                            target="_blank" href="https://hh.kz/resume/2da06569ff05abf6270039ed1f4d425a744b71"><i
                            class="far fa-address-card"></i></a></li>
                    <li class="list-inline-item" data-toggle="tooltip" data-placement="top" title="github"><a
                            target="_blank" href="https://github.com/vevovip"><i
                            class="fab fa-github-alt fa-fw"></i></a></li>
                    <li class="list-inline-item" data-toggle="tooltip" data-placement="top" title="gitlab"><a
                            target="_blank" href="https://gitlab.com/vevovip"><i
                            class="fab fa-gitlab fa-fw"></i></a></li>
                    <li class="list-inline-item" data-toggle="tooltip" data-placement="top" title="linkedin"><a
                            target="_blank" href="https://linkedin.com/in/akhmetyanov/"><i
                            class="fab fa-linkedin-in fa-fw"></i></a></li>
                    <li class="list-inline-item" data-toggle="tooltip" data-placement="top" title="stackoverflow"><a
                            target="_blank" href="https://stackoverflow.com/users/5734211/vevovip"><i
                            class="fab fa-stack-overflow fa-fw"></i></a></li>
                    <li class="list-inline-item" data-toggle="tooltip" data-placement="top" title="telegram"><a
                            target="_blank" href="https://t.me/vevovip"><i class="fab fa-telegram fa-fw"></i></a>
                    </li>
                    <li class="list-inline-item" data-toggle="tooltip" data-placement="top" title="skype"><a
                            target="_blank" href="https://join.skype.com/invite/EUdFaDgyLFki"><i
                            class="fab fa-skype fa-fw"></i></a></li>
                </ul>
            </div>
        </div>
    </section>

    <section class="profile-section">
        <div class="row my-3">
            <div class="col-md-8">

            </div>
            <div class="col-md-4">
            </div>
        </div>
    </section>

    <section class="overview-section">
        <div class="row mb-2">
            <h5>Основные технологии, которые я использую:</h5>
        </div>

        <div class="row ps-4 ps-md-5">
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon"><i class="fab fa-php"></i></div>
                    <h3 class="item-title">PHP</h3>
                    <div class="item-desc"></div>
                </div>
            </div>
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon"><i class="fab fa-html5 mr-2"></i><i class="fab fa-css3-alt"></i></div>
                    <h3 class="item-title">HTML &amp; CSS</h3>
                    <div class="item-desc"></div>
                </div>
            </div>
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon"><i class="fab fa-js-square"></i></div>
                    <h3 class="item-title">JavaScript</h3>
                    <div class="item-desc"></div>
                </div>
            </div>
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon"><i class="fab fa-vuejs"></i></div>
                    <h3 class="item-title">VueJS</h3>
                    <div class="item-desc"></div>
                </div>
            </div>
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon"><i class="fab fa-symfony"></i></div>
                    <h3 class="item-title">Symfony</h3>
                    <div class="item-desc"></div>
                </div>
            </div>
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon fa-yii2"><i class="fas fa-code"></i></div>
                    <h3 class="item-title">Yii2</h3>
                    <div class="item-desc"></div>
                </div>
            </div>
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon"><i class="fab fa-docker"></i></div>
                    <h3 class="item-title">Docker</h3>
                    <div class="item-desc"></div>
                </div>
            </div>
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon"><i class="fab fa-git"></i></div>
                    <h3 class="item-title">GIT</h3>
                    <div class="item-desc"></div>
                </div>
            </div>
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon"><span class="fa-mysql"><i class="fas fa-database"></i></span></div>
                    <h3 class="item-title">MySQL</h3>
                    <div class="item-desc"></div>
                </div>
            </div>
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon"><span class="fa-postgres"><i class="fas fa-database"></i></span></div>
                    <h3 class="item-title">PostgreSQL</h3>
                    <div class="item-desc"></div>
                </div>
            </div>
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon"><span class="fa-mongodb"><i class="fas fa-database"></i></span></div>
                    <h3 class="item-title">MongoDB</h3>
                    <div class="item-desc"></div>
                </div>
            </div>
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon"><span class="fa-redis"><i class="fas fa-database"></i></span></div>
                    <h3 class="item-title">Redis</h3>
                    <div class="item-desc"></div>
                </div>
            </div>
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon"><i class="fab fa-gitlab"></i></div>
                    <h3 class="item-title">GitLab CI/CD</h3>
                    <div class="item-desc"></div>
                </div>
            </div>
            <div class="item col-6 col-lg-3">
                <div class="item-inner">
                    <div class="item-icon"><i class="fas fa-terminal"></i></div>
                    <h3 class="item-title">Linux</h3>
                    <div class="item-desc"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {}
</script>

<style scoped>
    .overview-section .item {
        margin-bottom: 2rem
    }

    .overview-section .item-desc {
        color: #4f4f4f;
        font-size: 0.875rem
    }

    .overview-section .item-icon {
        font-size: 1.875rem;
        color: #54B689
    }

    .overview-section .item-icon .fa-angular {
        color: #CA3327
    }

    .overview-section .item-icon .fa-js-square {
        color: #F1DE4F
    }

    .overview-section .item-icon .fa-react {
        color: #62D4FA
    }

    .overview-section .item-icon .fa-vuejs {
        color: #64B687
    }

    .overview-section .item-icon .nodejs {
        color: #55AC63
    }

    .overview-section .item-icon .fa-python {
        color: #456E9C
    }

    .overview-section .item-icon .fa-php {
        color: #777BAF
    }

    .overview-section .item-icon .fa-java {
        color: #2E68AB
    }

    .overview-section .item-icon .fa-symfony {
        color: #e5e8e4
    }

    .overview-section .item-icon .fa-docker {
        color: #2496ed
    }

    .overview-section .item-icon .fa-redis {
        color: #d92b21
    }

    .overview-section .item-icon .fa-git {
        color: #413932
    }

    .overview-section .item-icon .fa-postgres {
        color: #31648c
    }

    .overview-section .item-icon .fa-mongodb {
        color: #0fa54d
    }

    .overview-section .item-icon .fa-terminal {
        color: #efb80e
    }

    .overview-section .item-icon .fa-yii2 {
        color: #bfd1da
    }

    .overview-section .item-icon .fa-mysql {
        color: #00758f
    }

    .overview-section .item-icon .fa-html5, .overview-section .item-icon .fa-html5-alt {
        color: #DE6E3C
    }

    .overview-section .item-icon .fa-css3, .overview-section .item-icon .fa-css3-alt {
        color: #53A7DC
    }

    .overview-section .item-icon .fa-sass {
        color: #BF6B97
    }

    .overview-section .item-icon .fa-less {
        color: #324B7C
    }

    .overview-section .item-icon .fa-gulp {
        color: #C9514E
    }

    .overview-section .item-icon .fa-grunt {
        color: #EFAC42
    }

    .overview-section .item-icon .fa-npm {
        color: #BB443E
    }

    .overview-section .item-title {
        font-size: 1rem
    }

    .overview-section .item-icon .fa-gitlab {
        color: #f46a25
    }

    .social-list {
        border-top: 1px solid rgba(255, 255, 255, 0.05);
    }

    .social-list a {
        width: 32px;
        height: 32px;
        padding-top: 5px;
        display: inline-block;
        text-align: center;
        border-radius: 50%;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
        background-color: #fff;
    }

    @media (prefers-reduced-motion: reduce) {
        .social-list a {
            -webkit-transition: none;
            transition: none
        }
    }

    .social-list a:hover {
        color: #40976f;
        background-color: rgba(255, 255, 255, 0.8)
    }
</style>
